import "./translateButton.scss";
import useLocalStorage from "../translater/hooks/useLocalStorage";
import i18n from '../translater/i18n';

const TranslateButton = () => {
    const [language, setLanguage] = useLocalStorage('language', 'uz');
    
    const handleLanguageChange = () => {
        const newLanguage = language === 'ru' ? 'uz' : 'ru';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    };

    return (
        <div className="translate">
            <input
                type="checkbox"
                onChange={handleLanguageChange}
                id="check1"
                className="toggle"
                checked={language === 'ru'}
            />
            <label htmlFor="check1">{language}</label>
        </div>
    );
};

export default TranslateButton;
