import React, { createContext, useContext, useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import TranslateButton from '../translater/TranslateButton';
import UserRegistration from './user/UserRegistration';
import img from '../../resources/img/bg_eagle.png';
import './app.scss';

import Spinner from '../modules/information/ui/spinner/Spinner';

const AboutProjectPage = lazy(() => import('../pages/AboutProjectPage'));
const MainPage = lazy(() => import('../pages/MainPage'));
const InformationPage = lazy(() => import('../pages/InformationPage'));
const RankingsPage = lazy(() => import('../pages/RankingsPage'));
// const RulesPage = lazy(() => import('../pages/RulesPage'));
const TournamentsPage = lazy(() => import('../pages/TournamentsPage'));
const TransfersPage = lazy(() => import('../pages/TransfersPage'));
const PlayersRankingsPage = lazy(() => import('../pages/PlayersRankingsPage'));
const PlayersInformationPage = lazy(() => import('../pages/PlayersInformationPage'));
const LeaguesPage = lazy(() => import('../pages/LeaguesPage'));
const PlayoffPage = lazy(() => import('../pages/PlayoffPage'));
const TeamPage = lazy(() => import('../pages/TeamPage'));
const Page404 = lazy(() => import("../pages/404"))

const TeamContext = createContext();
export const useTeamContext = () => useContext(TeamContext);

function App() {
    const [isUser, setIsUser] = useState(true);
    const [team, setTeam] = useState(null);
    const [teamLogo, setTeamLogo] = useState(null);
    const updateTeam = (newTeam) => {
        setTeam(newTeam);
    };

    const updateTeamLogo = (newLogo) => {
        setTeamLogo(newLogo);
    };

    useEffect(() => {
        if (localStorage.getItem("username") !== null) {
            setIsUser(true);
        } else {
            setIsUser(false);
        }
    }, []);

    const handleChangeUser = (isReg) => {
        setIsUser(isReg);
    };

    return (
        <BrowserRouter >
            <img src={img} className="img1" alt="img" />
            <TeamContext.Provider value={{ team, teamLogo, updateTeam, updateTeamLogo }}>
                <div>
                    <div className="app">
                        <TranslateButton />

                        {/* {!isUser && <UserRegistration handleChangeUser={handleChangeUser} />}

                        {isUser && (
                            <>
                                
                            </>
                        )}
                        */}
                        <Navbar />
                        <Suspense fallback={<Spinner />}>
                            <Routes>
                                <Route path="/" end element={<MainPage />} />
                                <Route path="/rankings" element={<RankingsPage />} />
                                <Route path="/rankings/players" element={<PlayersRankingsPage />} />
                                <Route path="/tournament" element={<TournamentsPage />} />
                                <Route path="/tournament/playoff" element={<PlayoffPage />} />
                                <Route path="/tournament/leagues" element={<LeaguesPage />} />
                                <Route path="/transfers" element={<TransfersPage />} />
                                <Route path="/information" element={<InformationPage />} />
                                <Route path="/team" element={<TeamPage />} />
                                <Route path="/information/players" element={<PlayersInformationPage />} />
                                {/* <Route path="/rules"  element={<RulesPage/>}/> */}
                                <Route path="/about" element={<AboutProjectPage />} />
                                <Route path="*" element={<Page404/>}/>
                            </Routes>
                        </Suspense>
                    </div>
                </div>
            </TeamContext.Provider>
        </BrowserRouter>
    );
}

export default App;
