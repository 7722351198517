import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHouse,faRankingStar,faTrophy,faRightLeft,faCircleInfo,faAnglesRight} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import "./navbar.scss";


const Navbar = () => {
  const {t} = useTranslation();

  const navLinks = [
    { to: "/", label: t("main") , icon: <FontAwesomeIcon className = "svg" icon={faHouse}/>},
    { to: "/rankings", label: t("rankings"),icon: <FontAwesomeIcon className = "svg" icon={faRankingStar}/>},
    { to: "/tournament", label: t("tournament"), icon: <FontAwesomeIcon className = "svg" icon={faTrophy}/>},
    { to: "/information", label: t("information"), icon: <FontAwesomeIcon className = "svg" icon={faCircleInfo}/>},
    { to: "/transfers", label: t("transfers"), icon: <FontAwesomeIcon className = "svg" icon={faRightLeft}/>},/* { to: "/rules", label: "Правила", icon: *//* 
    { to: "/about", label: t("about"), icon: <FontAwesomeIcon className = "svg" icon={faLaptop}/>} */
  ];

  return ( 
    <div className="navbar">  
      <ul className="navbar-nav">
      <li className = "logo">
        <div className = "nav-link">
          <span className = "link-text logo-text">eF teams<p style={{fontSize: "10px"}}>2024</p></span>
          <FontAwesomeIcon className = "svg" icon={faAnglesRight}/>
        </div>
      </li>
        {navLinks.map((link) => (
          <li key={link.to} className = "nav-item">
            <NavLink to={link.to} className = "nav-link"  style={({ isActive }) => ({ transform: isActive ? "scale(1.2)" : 'none' })}>
            {link.icon}
            <span className="link-text">{link.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
